import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {TicketsService} from '../../../services/tickets.service';
import * as moment from 'moment';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {CheckoutComponent} from '../../checkout/checkout.component';
@Component({
  selector: 'product-tickets',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ProductTicketsComponent implements OnInit {
  @Input() product;
  @Input() scheme;
  public numberOfPurchases = 0;
  public ticketsLoading = false;
  public showFilter = true;
  public isBallot = null;
  public filter = null;
  public displayMonth = null;
  public selectedTicket;
  public tickets = {
    locations: {},
    dates: {},
    stock_quantity: null,
    available: false,
  };

  public _tickets = {
    locations: {},
    dates: {},
    stock_quantity: null,
    available: false,
  };
  constructor(
    protected ticketsService: TicketsService,
    protected dialogRef: MatDialogRef<CheckoutComponent>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadTickets();
    this.setType();
  }

  formatPrice(ticketPrice) {
    let priceString =
      ticketPrice > 0 ? `£${(ticketPrice / 100).toFixed(2)}` : 'free';

    return priceString;
  }

  public doCheck() {
    if (this.product.hasOwnProperty('purchased')) {
      const newNumberOfPurchases = this.product.purchased.length;

      newNumberOfPurchases !== this.numberOfPurchases && this.loadTickets();

      this.numberOfPurchases = newNumberOfPurchases;
    }
  }

  public setType() {
    this.isBallot = false;

    if (this.product.hasOwnProperty('features')) {
      this.isBallot =
        Object.keys(this.product.features).indexOf('ballot') !== -1;
    }
  }

  public setMonth(dateString) {
    this.displayMonth = this.displayMonth !== dateString ? dateString : null;
  }

  public setFilter(locationId) {
    const clonedTickets = Object.assign({}, this._tickets);
    let dates = Object.keys(clonedTickets.dates).sort().map(key => {
      clonedTickets.dates[key].dateString = key;
      return clonedTickets.dates[key];
    });
    this.filter = locationId ? locationId : null;
    dates = dates.filter(month => {
      return !this.filter || month.locationIds.indexOf(this.filter) !== -1;
    });
    const stock = clonedTickets.stock_quantity;
    clonedTickets.dates = dates;
    clonedTickets.available = !!Object.keys(dates).length && !!stock;

    this.tickets = clonedTickets;
  }

  public pickOptions(ticket) {
    this.selectedTicket = ticket;
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = {product: this.product, variant: ticket};
    this.dialog
      .open(CheckoutComponent, config)
      .afterClosed()
      .subscribe(() => {});
  }

  public loadTickets() {
    this.ticketsLoading = true;
    this.ticketsService.load(this.product.id).then(response => {
      this._tickets = Object.assign({}, response);

      this.showFilter = Object.keys(response.locations).length > 1;
      this.setFilter(this.filter);
      this.ticketsLoading = false;
    });
  }

  public checkStagedRelease(ticket) {
    const releaseCutOff = moment(ticket.release_date);

    if (moment().isBefore(releaseCutOff.set('hours', 7))) {
      return ` Tickets on sale from ${releaseCutOff.format(
        'dddd DD MMMM'
      )} at 7am`;
    } else if (
      moment().isAfter(releaseCutOff) &&
      moment().isBefore(releaseCutOff.set('hours', 22)) &&
      ticket.stock_quantity <= 0
    ) {
      return `More Tickets on sale from ${releaseCutOff.format(
        'dddd DD MMMM'
      )} at 10pm`;
    } else if (
      ticket.stock_quantity <= 0 &&
      moment().isAfter(releaseCutOff.set('hours', 22))
    ) {
      return 'Sold Out';
    } else {
      return 'Buy Now';
    }
  }
}
