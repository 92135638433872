<div class="page-loading" *ngIf="loading">
  <span>
      <span></span>
  </span>
</div>
<div
  class="grid-layout {{ category.slug }}"
  data-infinite-scroll="$ctrl.nextPage()"
  data-infinite-scroll-distance="0"
  *ngIf="category"
>
  <div class="category-image-header row clearfix max-width">
    <div class="image" *ngIf="category.banner_image_name">
      <img
        alt="{{ category.title }}"
        [src]="
          '/CategoryImages/' +
          category.id +
          '/banner/' +
          category.banner_image_name
        "
      />
    </div>
    <div class="title">
      <h1>
        <span
          *ngIf="category.font_icon"
          class="fa fa-fw"
          [ngClass]="category.font_icon"
        ></span>
        {{ category.title }}
      </h1>
    </div>
    <div class="description" [innerHTML]="category.description"></div>
  </div>

  <product-list class="{{category.slug}}" [items]="category.products"></product-list>
</div>
