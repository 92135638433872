<div class="cards-carousel">
  <div
    [ngClass]="{
      'card-carousel grid-layout static is-set homepage-redesign': sortedItems.length,
      carousel: sortedItems.length > 3
    }"
    *ngIf="sortedItems && sortedItems.length"
    (swipeleft)="next(true)"
    (swiperight)="previous(true)"
    data-hm-recognizer-options="{directions: 'DIRECTION_HORIZONTAL'}"
  >
    <div
      class="item {{item.colour_scheme}}"
      *ngFor="let item of sortedItems"
      [ngStyle]="{order: item.carouselOrder}"
    >
      <div class="item-contents" (click)="navigate(item)">
        <div class="image">
          <img
            [src]="item.thumbnailUrl"
            loading="lazy"
            alt="{{ item.title }}"
          />
        </div>
        <div class="description">
          <h3>{{ item.menu_title }}</h3>
        </div>
        <div class="item-price">
          <product-list-price [product]="item"></product-list-price>
          <div class="view-details">
            <p>View Details <span class="fa fa-chevron-circle-right"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="controls static"
    [ngClass]="{
      'xl-over-4': items.length > 4,
      'lg-over-3': items.length > 3,
      'md-over-2': items.length > 2,
      'sm-over-1': items.length > 1
    }"
  >
    <button class="previous" (click)="previous(true)">
      <i class="fa fa-solid fa-chevron-left"></i>

    </button>
    <button class="next" (click)="next(true)">
      <i class="fa fa-solid fa-chevron-right"></i>
      
    </button>
  </div>
</div>
