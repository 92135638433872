import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'product-price',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ProductPriceComponent implements OnInit {
  @Input() listing;
  @Input() scheme;
  public price;

  constructor() {}
  ngOnInit(): void {
    this.price = this.setPrice();
  }

  text() {
    const {price_label = '', price_label_value = ''} = this.listing;
    return {
      price: `${price_label || ''} ${price_label_value || ''}`.trim() || null,
    };
  }

  setPrice() {
    const isPurchasable = !!this.listing.features.purchasable;

    this.price = isPurchasable ? this.priceFunc() : this.text();
    return this.price;
  }

  priceFunc() {
    return {
      title: this.listing.from_price ? 'from' : null,
      price:
        this.listing.from_price > 0
          ? `£${(this.listing.from_price / 100).toFixed(2)}`
          : 'free',
    };
  }
}