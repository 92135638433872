
<div
  [ngClass]="!isLoggedIn ? 'logged-out-header' : 'logged-in-header'"
  class="menu-section"
>
 <div>
    <a (click)="navigate('/')" class="brand">
      <a (click)="navigate('/')" class="second-logo"></a>
    </a>
    <div class="social-icons hide-mobile">
      <a href="https://www.partnerchoice.co.uk/listing/9216/partnerchoice-app"
        ><span class="fa fa-fw fa-lg fa-brands fa-apple"></span
      ></a>
      <a href="https://www.partnerchoice.co.uk/listing/9216/partnerchoice-app"
        ><span class="fa fa-fw fa-lg fa-brands fa-android"></span
      ></a>
      <a [href]="gazetteUrl" target="blank" class="gazette"
        ><img
          class="fa fa-fw fa-lg"
          src="/app/assets/img/homepage/Gazette_Logo.png"
      /></a>
    </div>
  </div>
  <nav class="top-nav">
    <ul class="inline-list left hide-desktop">
      <li
        id="mobileHamburger"
        class="left-panel-button fa fa-fw fa-lg fa-bars modal-container"
      ></li>
      <!-- data-ui-sref="Page.Home" -->
      <li
        *ngIf="isLoggedIn"
        class="fa fa-fw fa-lg fa-home"
      ></li>
    </ul>
    <div class="hide-desktop mobile-logo"></div>

    <ul *ngIf="isLoggedIn" class="inline-list right" id="account">
      <li
        id="mobileSearch"
        class="top-panel-button fa fa-fw fa-lg fa-search hide-desktop modal-container"
      ></li>
      <li class="right-panel-button fa fa-fw fa-lg fa-user modal-container" id="account">
        <span *ngIf="user" class="user-name-dropdown hide-mobile" id="account">
          {{ user.first_name + ' ' + user.last_name }}
          <span id="account" class="fa fa-chevron-circle-down"></span>
        </span>
      </li>
    </ul>
    <div class="left-panel">
      <nav>
        <ul>
          <li>
            <a href="https://jlpleisurebenefits.zendesk.com/home" title=""
              >Help?</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </nav>
  <search-panel
    *ngIf="isLoggedIn"
    [isLoggedIn]="isLoggedIn"
    [(mobileSearchOpen)]="mobileSearchOpen"
  />
  <user-menu [isAdmin]="user?.isAdmin"/> 
</div>
