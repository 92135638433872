import {Injectable} from '@angular/core';
import environment from '../../environment/index.js';
import {ApiService} from './api.service';

@Injectable()
export class GazetteService {
  constructor(protected Api: ApiService) {
  }

  public load() {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/gazette`
    ).then(data => {
      return data;
    });
  }
}
