import { enableProdMode } from '@angular/core';
import '@angular/compiler';
import 'zone.js';
import {UpgradeModule} from '@angular/upgrade/static';
import { AppModule } from './app.module';
import { platformBrowser } from '@angular/platform-browser';
import environment  from './environment/index';

if (environment.settings.stage === 'production') {
  enableProdMode();
}

platformBrowser().bootstrapModule(AppModule)
  .catch(err => console.error(err));
