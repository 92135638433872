import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {GazetteService} from '../../services/gazette-service';

import {BehaviorSubject, Subscription as RxSubscription} from 'rxjs';
import {CommonService} from '../../services/common.service';
@Component({
  selector: 'pc-header',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class HeaderComponent {
  public openMenu = false;
  @Input() isLoggedIn: string | boolean | null;

  public mobileSearchOpen = false;
  private Subscriptions: RxSubscription[] = [];
  public gazetteUrl;

  public user;
  constructor(
    public authService: AuthService,
    public userService: UserService,
    public gazetteService: GazetteService,
    public commonService: CommonService
  ) {
    this.gazetteService.load().then(data => (this.gazetteUrl = data));
  }

  ngOnInit() {
    this.Subscriptions.push(
      this.userService.User.subscribe(data => (this.user = data.User))
    );
  }

  public navigate(path) {
    this.commonService.navigate(path);
  }
}