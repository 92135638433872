<div class="page-loading" *ngIf="loading">
  <span>
      <span></span>
  </span>
</div>
<section *ngIf="carouselItems">
  <section *ngIf="mainCarousels.items[0]" class="flex-row full-row">
    <a
      (click)="
        navigate(
          '/listing/' +
            mainCarousels.items[0].id +
            '/' +
            mainCarousels.items[0].slug
        )
      "
    >
      <img
        src="/CompanyImages/{{ mainCarousels.items[0].carousel_image_id }}/{{
          mainCarousels.items[0].slug
        }}"
      />
    </a>
  </section>

  <section>
    <static-carousel />
  </section>

  <section class="flex-row half-row">
    <div *ngIf="homepageProducts.items[0]">
      <a
        (click)="
          navigate(
            '/listing/' +
              homepageProducts.items[0].id +
              '/' +
              homepageProducts.items[0].slug
          )
        "
      >
        <img
          src="/CompanyImages/{{
            homepageProducts.items[0].carousel_image_id
          }}/{{ homepageProducts.items[0].slug }}"
        />
      </a>
    </div>
    <div *ngIf="homepageProducts.items[1]">
      <a
        (click)="
          navigate(
            '/listing/' +
              homepageProducts.items[1].id +
              '/' +
              homepageProducts.items[1].slug
          )
        "
      >
        <img
          src="/CompanyImages/{{
            homepageProducts.items[1].carousel_image_id
          }}/{{ homepageProducts.items[1].slug }}"
        />
      </a>
    </div>
  </section>

  <section *ngIf="mainCarousels.items[1]" class="flex-row full-row">
    <a
      (click)="
        navigate(
          '/listing/' +
            mainCarousels.items[1].id +
            '/' +
            mainCarousels.items[1].slug
        )
      "
    >
      <img
        src="/CompanyImages/{{ mainCarousels.items[1].carousel_image_id }}/{{
          mainCarousels.items[1].slug
        }}"
      />
    </a>
  </section>

  <section class="flex-row half-row">
    <div *ngIf="homepageProducts.items[2]">
      <a
        (click)="
          navigate(
            '/listing/' +
              homepageProducts.items[2].id +
              '/' +
              homepageProducts.items[2].slug
          )
        "
      >
        <img
          src="/CompanyImages/{{
            homepageProducts.items[2].carousel_image_id
          }}/{{ homepageProducts.items[2].slug }}"
        />
      </a>
    </div>
    <div *ngIf="homepageProducts.items[3]">
      <a
        (click)="
          navigate(
            '/listing/' +
              homepageProducts.items[3].id +
              '/' +
              homepageProducts.items[3].slug
          )
        "
      >
        <img
          src="/CompanyImages/{{
            homepageProducts.items[3].carousel_image_id
          }}/{{ homepageProducts.items[3].slug }}"
        />
      </a>
    </div>
  </section>
</section>
