import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MaterialModule} from '../../../material.module';
import { ExtraInfoComponent } from './extra-info/app.component';
import { ExtraInfoContentModalComponent } from './extra-info/extra-info-content/app.component';
import { MatDialogRef } from '@angular/material/dialog';
import { CarouselModule } from '../../component/carousel/module';
import { ProductListComponent } from './product/product-list/app.component';
import { ProductListPriceComponent } from './product/product-list/product-list-price/app.component';
import {ProductPriceComponent } from './product-price/app.component';

@NgModule({
  imports: [CommonModule, MaterialModule, CarouselModule],
  declarations: [
    ExtraInfoComponent,
    ExtraInfoContentModalComponent,
    ProductListComponent,
    ProductListPriceComponent,
    ProductPriceComponent
    
  ],
  exports: [ExtraInfoComponent, ExtraInfoContentModalComponent,
    ProductListComponent,
    ProductListPriceComponent,
    ProductPriceComponent

  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
