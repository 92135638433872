import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';

@Injectable()
export class SearchService {
  constructor(protected Api: ApiService) {}

  public searchListing(searchTerm) {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/search?searchTerm=${searchTerm}`
    ).then(({data}) => {

      return data;
    });
  }

  public surpriseMe() {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/surprise-me`
    ).then((data) => {
      return data;
    });
  }


}
