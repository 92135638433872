import {Component, OnInit} from '@angular/core';
import {ListingService} from '../../services/listing.service';
import {CommonService} from '../../services/common.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {IframeButtonComponent} from './iframe-button/app.component';

@Component({
  selector: 'listing-test',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ListingComponent implements OnInit {
  public listing;
  public relatedContent;
  public isUrl;
  public isFilmology;
  public isOdney = false;
  public iframe;
  public isProduct = false;
  public isTicket = false;
  public loading: boolean;

  constructor(
    protected listingService: ListingService,
    public commonService: CommonService,
    protected Dialog: MatDialog
  ) {
    this.getData();
  }

  ngOnInit(): void {}

  getData() {
  
    this.loading = true;

    const listingId = window.location.pathname.split('/')[2];
    this.listingService.load(listingId).then(listing => {
      if (listing.video) {
        const newImages = listing.ProductImage;
        newImages.push({id: 'video', video: listing.video});
        this.listing = {...listing, ProductImage: newImages};
      } else {
        this.listing = listing;
      }
      this.isUrl = !!listing.features?.external_url;
      this.isFilmology = !!listing.features.filmology;
      this.iframe = listing.features.iframe
        ? {title: 'Open Issuu Magazine', ...listing.features.iframe}
        : null;
      this.isProduct = listing.features.purchasable && listing.features.product;
      this.isOdney = listing.features.purchasable && listing.features.odney;
      this.isTicket =
        !!listing.features.purchasable &&
        (listing.features.ticket || listing.features.ballot);
      this.loading = false;

;
    });
    this.listingService
      .loadRelatedContent(listingId)
      .then(data => (this.relatedContent = data));
  }

  public navigate(path) {
    window.location.href = path;
  }

  public showTheIssue() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = this.listing.iframe;
    this.Dialog.open(IframeButtonComponent, config).afterClosed();
  }
}
