import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';
import environment from '../../environment';

@Injectable()
export class UserService {
  constructor(protected Api: ApiService) {
    this.loadUser();
  }
  public User: BehaviorSubject<any> = new BehaviorSubject([]);

  public async loadUser() {
    const result = await this.Api.get( `${environment.settings.registeredAwsUrl}/user`);
    this.User.next(result.data);
  }
}
