import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {VideoModalComponent} from '../video-modal/app.component';

@Component({
  selector: 'image-carousel',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class ImageCarouselComponent
  implements OnInit, AfterViewInit, OnChanges
{
  public thumbnails;
  public loading = false;
  public activeSlide;
  public timer = null;
  public next = this.nextSlide;
  public previous = this.previousSlide;
  public step = 4750;
  public delay = 250;
  public rootElem;
  public isCarousel;
  el: ElementRef;
  @Input() items = [];
  @Input() slug;
  @Input() scheme;

  constructor(el: ElementRef, private dialog: MatDialog) {
    this.el = el;
  }
  ngOnInit(): void {
    this.rootElem = $($(this.el.nativeElement).children('.image-carousel')[0]);
    this.isCarousel = this.items.length > 1;

  }

  ngAfterViewInit() {}

  public async ngOnChanges() {

    this.loading = true;
    await this.setVideo();

    if (!this.isCarousel) {
      return;
    }

    this.setTheOrder();

    this.rootElem.children().removeClass('static');
    !!this.timer && clearTimeout(this.timer);
    this.loading = false;
  }

  public nextSlide(manual) {
    !!this.timer && clearTimeout(this.timer);

    this.moveForward();

    !manual && (this.timer = setTimeout(this.nextSlide, this.step));
  }

  public previousSlide(manual) {
    !!this.timer && clearTimeout(this.timer);

    this.moveBackward();

    !manual && (this.timer = setTimeout(this.nextSlide, this.step));
  }

  public moveForward() {
    const $el = this.rootElem.children('.carousel');

    $el.removeClass('is-set').removeClass('is-reversing');

    this.items.forEach(item => {
      item.carouselOrder--;

      item.carouselOrder < 0 && (item.carouselOrder += this.items.length);

      item.carouselOrder === 2 && (this.activeSlide = item);
    });

    setTimeout(() => {
      $el.addClass('is-set');
    });
  }

  public moveBackward() {
    this.rootElem
      .children('.carousel')
      .removeClass('is-set')
      .addClass('is-reversing');

    this.items.forEach(item => {
      item.carouselOrder++;

      item.carouselOrder >= this.items.length &&
        (item.carouselOrder -= this.items.length);

      item.carouselOrder === 2 && (this.activeSlide = item);
    });

    setTimeout(() => {
      this.rootElem.children('.carousel').addClass('is-set');
    }, this.delay);
  }

  public setTheOrder() {
    let index = 0;
    this.thumbnails = this.items;

    if (!this.rootElem.hasClass('no-duplicate')) {
      this.items.length > 1 &&
  
        (this.items = this.items.concat(this.items.map(item => ({...item}))));
      index = 2;
    }

    this.items.forEach(item => {
      item.carouselOrder = index;

      index++;

      index >= this.items.length && (index -= this.items.length);
    });
  }

  public onThumbnailClick(thumbnail) {
    const {id: thumbnailId} = thumbnail;
    const {id: activeSlideId} = this.activeSlide || {id: null};
    if (thumbnailId !== activeSlideId) {
      this.nextSlide(true);
      setTimeout(() => {
        this.onThumbnailClick(thumbnail);
      }, 50);
    }
  }

  public async setVideo() {
    const item = this.items ? this.items.filter(({video}) => video)[0] : null;

    if (!item) {
      return;
    }

    try {
      const {video: url} = item;

      if (url.includes('youtube')) {
        return this.setYouTubeVideo(item);
      } else if (url.includes('vimeo')) {
        await this.setVimeoVideo(item);
      } else {
        this.removeItem(item);
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.resolve();
    }
  }

  public setYouTubeVideo(item) {
    const id = item.video.split('v=')[1];

    this.removeItem(item);
    this.items.push({
      id: 'video',
      title: 'YouTube Video',
      src: `https://www.youtube.com/embed/${id}?autoplay=1`,
      thumbnail:`https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
    });
    return Promise.resolve();
  }
  public async setVimeoVideo(item) {
    const {video: url} = item;
    const endpoint = `https://vimeo.com/api/oembed.json?url=${url}&autoplay=1`;

    return await fetch(endpoint)
      .then(response => response.json())
      .then(
        async ({
          title = 'Vimeo Video',
          html = '',
          thumbnail_url: thumbnail,
        }) => {
          const matcher = /<iframe.*?src="(.*?)"[^>]+>/g;
          const [, src] = matcher.exec(html);

          this.removeItem(item);
          this.items.push({
            id: 'video',
            title,
            src,
            thumbnail,
          });
        }
      )
      .catch(() => this.removeItem(item));
  }

  public removeItem(item) {
    const index = this.items.indexOf(item);
    index && this.items.splice(index, 1);
  }

  public showVideo(video) {
    const config = new MatDialogConfig();
    config.data = video;

    this.dialog.open(VideoModalComponent, config);
  }
}
