import {Component, Input, OnInit} from '@angular/core';
import {ListingService} from '../../../services/listing.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {CheckoutComponent} from '../../checkout/checkout.component';

@Component({
  selector: 'product-products',
  templateUrl: './product-products.component.html',
  styleUrls: ['./product-products.component.scss'],
})
export class ProductProductsComponent implements OnInit {
  @Input() product: any;
  @Input() scheme;

  public items = [];

  constructor(
    protected listingService: ListingService,
    protected dialogRef: MatDialogRef<CheckoutComponent>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadItems();
  }

  async loadItems() {
    this.items = await this.listingService.loadListingProducts(
      this.product.id
    );
  }

  pickOptions(item) {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = {product: this.product, variant: item};
    this.dialog
      .open(CheckoutComponent, config)
      .afterClosed()
      .subscribe(() => {});
  }
}
