import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public loading = false;

  constructor(
    public fb: FormBuilder,
    private loginService: LoginService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  async onSubmit() {
    try {
      if (this.form.invalid) {
        return;
      }
      this.loading = true;

       await this.loginService.awsLogin(
        this.form.getRawValue().email,
        this.form.getRawValue().password
      ).then(res => this.handleAWSLoginSuccess(res));
      ;

      this.loading = false;
      const redirect = localStorage.getItem('loginRedirect');
      if (redirect) {
        localStorage.removeItem('loginRedirect');
        this.commonService.navigate(redirect);
      } else {
        this.commonService.navigate('/');
      }
    } catch (error) {
      document.getElementById('warning').innerText = error?.error?.error || "Something went wrong";
      this.loading = false;
    }
  }

  handleAWSLoginSuccess(response) {
    localStorage.setItem('partnerchoice_api_token', response.token);
    localStorage.setItem(
      'partnerchoice_api_refresh_token',
      response.refreshToken
    );
  }

  handleLeisureLoginSuccess(leisureResponse) {
    localStorage.setItem('AuthToken', JSON.stringify(leisureResponse.authorizationHeader));
  }
}
