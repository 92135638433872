
import '@angular/compiler';
import 'zone.js';
import 'reflect-metadata';
import {CommonModule} from '@angular/common';

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UpgradeModule} from '@angular/upgrade/static';

import * as angular from 'angular';
import {setAngularJSGlobal} from '@angular/upgrade/static';
import {AppComponent} from './angular/appComponent/app.component';
import {AppRoutingModule} from './app-routing.module';
import {NoPathFound} from './angular/component/not-found/app.comonent';
import {FooterComponent} from './angular/component/footer/app.component';
import {AuthService, tokenGetter} from './angular/services/auth.service';
import {JwtModule} from '@auth0/angular-jwt';
import {ApiService} from './angular/services/api.service';
import {UserService} from './angular/services/user.service';
import {CommonService} from './angular/services/common.service';

import {HttpClientModule} from '@angular/common/http';
import {HeaderModule} from './angular/component/header/module';
import {HomepageModule} from './angular/modules/homepage/module';
import {TestModule} from './angular/modules/test/module';

@NgModule({
  imports: [
    HttpClientModule,
    HomepageModule,
    HeaderModule,
    BrowserModule,
    UpgradeModule,
    CommonModule,
    TestModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {tokenGetter: tokenGetter},
    }),
  ],
  exports: [HeaderModule, HomepageModule, TestModule],
  declarations: [AppComponent, NoPathFound, FooterComponent],
  bootstrap: [AppComponent],
  providers: [AuthService, ApiService, UserService, CommonService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

setAngularJSGlobal(angular);
