import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {tsAndCs} from '../../../../environment/constants';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'checkout-option',
  templateUrl: './checkout-option.component.html',
  styleUrls: ['./checkout-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutOptionComponent implements OnInit {
  @Input() activePanel: BehaviorSubject<string>;
  @Input() variant: any = {};
  @Input() product: any = {};
  @Input() setOptions: any = {};
  @Input() close: any = {};
  @Input() status: any = {};

  public pickOptionsForm: FormGroup;
  public total = {
    cost: 0,
    tickets: 0,
  };
  public tooManyTickets = false;
  public tooManySupplements = false;
  public showHurry: boolean = false;
  public tsAndCs = tsAndCs.ticket;
  public terms: boolean = false;
  public isFree: boolean = false;
  public isAnarchy: boolean = false;
  public tickets: any = {};
  public errors: any = {};

  constructor(private readonly fb: FormBuilder) {
    this.pickOptionsForm = this.fb.group({});
  }

  ngOnInit(): void {
    if (
      this.product.hasOwnProperty('terms_and_conditions') &&
      !!this.product.terms_and_conditions
    ) {
      this.tsAndCs = this.product.terms_and_conditions;
    }
    this.isAnarchy = this.variant.features.anarchy;
    this.tickets = [...this.variant.prices].reduce((tickets, price) => {
      tickets[price.slug] = {
        label: price.label,
        amount: null,
      };

      return tickets;
    }, {});

    this.isFree = !this.variant.prices
      .map(price => price.price)
      .reduce((total, price) => total + price, 0);

      console.log(this.variant);
    this.showHurry =
      this.variant.stock_quantity !== null &&
      this.variant.stock_quantity <= this.variant.tickets_per_partner;

    this.initializeForm();
  }

  initializeForm() {
    this.variant.prices.forEach(price => {
      this.pickOptionsForm.addControl(
        price.slug,
        new FormControl(0, [Validators.min(0)])
      );
    });

    this.variant.supplements.forEach(supplement => {
      this.pickOptionsForm.addControl(
        supplement.slug,
        new FormControl(0, [Validators.min(0)])
      );
    });
  }

  addOptions() {
    this.setOptions(this.tickets);
  }

  updateTotal() {
    this.total.cost = 0;
    this.total.tickets = 0;

    Object.keys(this.pickOptionsForm.controls).forEach(key => {
      const control = this.pickOptionsForm.get(key);
      if (control) {
        const amount = control.value;
        this.tickets[key] = {amount, label: this.tickets[key].label};
        const price =
          this.variant.prices.find(p => p.slug === key) ||
          this.variant.supplements.find(s => s.slug === key);
        if (price) {
          this.total.tickets += amount;
          this.total.cost += amount * price.price;
        }
      }
    });

    this.variant.supplements.forEach(supplement => {
      if (this.tickets.hasOwnProperty(supplement.slug)) {
        const numberOfSupplements = this.tickets[supplement.slug].amount;

        this.total.cost += supplement.price * numberOfSupplements;
      }
    });

    this.tooManyTickets =
      this.total.tickets > this.variant.tickets_per_partner ||
      this.total.tickets > this.variant.stock_quantity;

    if (this.isAnarchy) {
      this.oneFreeTextPerTicket();
    }
  }

  disabled() {
    return (
      this.pickOptionsForm.invalid ||
      this.total.tickets === 0 ||
      !this.terms ||
      this.total.tickets > this.variant.stock_quantity ||
      (this.variant.tickets_per_partner &&
        this.total.tickets > this.variant.tickets_per_partner) ||
      (!this.isAnarchy &&
        (this.partnerPriceTicketHasBeenOrdered() ||
          !!this.notSetUpErrorsForTickets() ||
          this.tooManySupplements))
    );
  }

  partnerPriceTicketHasBeenOrdered() {
    const partnerPrice = this.variant.prices[0].slug;

    return (
      !this.tickets.hasOwnProperty(partnerPrice) ||
      this.tickets[partnerPrice].amount <= 0
    );
  }

  notSetUpErrorsForTickets() {
    return Object.keys(this.errors).filter(key => this.errors[key]).length;
  }

  oneFreeTextPerTicket() {
    this.variant.supplements.forEach(supplement => {
      if (
        this.tickets.hasOwnProperty(supplement.slug) &&
        this.tickets[supplement.slug].amount > this.total.tickets
      ) {
        this.tooManySupplements = true;
      }
      this.tooManySupplements = false;
    });
  }
}
