<div class="page-loading" *ngIf="loading">
  <span>
    <span></span>
  </span>
</div>

<div class="product-contain" *ngIf="listing">
  <image-carousel
    *ngIf="listing && listing.ProductImage"
    [items]="listing.ProductImage"
    [slug]="listing.slug"
    [scheme]="listing.colour_scheme"
  ></image-carousel>

  <section class="container">
    <h1>{{ listing?.title }}</h1>

    <product-price
      *ngIf="listing?.features"
      [listing]="listing"
      [scheme]="listing.colour_scheme"
    ></product-price>

    <extra-info
      [scheme]="listing.colour_scheme"
      *ngIf="listing.ProductExtraInfo"
      type="payment"
      [productInfo]="listing.ProductExtraInfo"
    >
    </extra-info>
    <div *ngIf="isUrl">
      <div>
        <a [href]="listing.features.external_url" target="_blank">
          <button>
            <span class="fa fa-external-link"></span>
            {{ listing.features.button_text || 'Apply Online' }}
          </button>
        </a>
      </div>
    </div>
    <div *ngIf="iframe?.url">
      <button (click)="showTheIssue()">{{ iframe.title }}</button>
    </div>

    <div *ngIf="isFilmology">
      <form
        action="https://www.cinemabenefits.co.uk/johnlewis/index.php"
        method="post"
        name="FV_form"
        enctype="multipart/form-data"
      >
        <input
          type="hidden"
          name="fv_value"
          id="fv_value"
          value="KSgdq29d7jw8v6ds1"
        />
        <input
          class="button"
          type="submit"
          id="submit"
          value="Click here to buy cinema tickets"
          href="
            https://www.cinemabenefits.co.uk/johnlewis/index.php
          "
        />
      </form>
    </div>

    <product-tickets
      *ngIf="isTicket"
      [scheme]="listing.colour_scheme"
      [product]="listing"
    ></product-tickets>

    <product-products
      *ngIf="isProduct"
      [scheme]="listing.colour_scheme"
      [product]="listing"
    ></product-products>
  </section>

  <div class="container product-content">
    <section
      class="product-description"
      [innerHtml]="listing.description"
    ></section>

    <extra-info
      [scheme]="listing.colour_scheme"
      type="product"
      *ngIf="listing.ProductExtraInfo"
      [productInfo]="listing.ProductExtraInfo"
    >
    </extra-info>
    <div
      class="row clearfix max-width"
      style="margin-top: 20px"
      *ngIf="relatedContent && relatedContent.length > 0"
    >
      <div class="small-12 columns">
        <div>
          <h2>Related Pages</h2>
        </div>
      </div>
    </div>
    <cards-carousel
      *ngIf="relatedContent && relatedContent.length > 0"
      [scheme]="listing.colour_scheme"
      class="no-duplicate"
      overflow-scroll="true"
      [items]="relatedContent"
    ></cards-carousel>
  </div>
</div>
