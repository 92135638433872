import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'product-list-price',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class ProductListPriceComponent implements OnInit {
  @Input() product;
  public priceString;
  constructor() {}

  ngOnInit(): void {
    const purchasable =
      this.product.feature_flags &&
      this.product.feature_flags.split('/').includes('purchasable');
    const {label, value} = purchasable
      ? this.purchasable()
      : this.nonPurchasable();
    const priceValue = value !== null ? value : '';
    this.priceString = `${label} ${priceValue}`;

  }

  purchasable() {
    const {from_price: fromPrice} = this.product;
    const label = 'Partner Price',
      value = fromPrice > 0 ? this.getPriceString(fromPrice) : 'Free';
    return {
      label,
      value,
    };
  }

  nonPurchasable() {
    let obj;

    if(this.product.price_label !== null && this.product.price_label_value !== null) {
      obj = {
        label: this.product.price_label || '&nbsp;',
        value: this.product.price_label_value,
      };
    } else {
      obj = {
        label: 'View',
        value: ' for more information',
      };
    }
    return obj;
  }

  getPriceString(price) {
    const fraction = !(price % 100) ? 0 : 2;
    return `£${(price / 100).toFixed(fraction)}`;
  }
}
