<section class="product-products {{ scheme }}">
  <ng-container class="product-flex-row" *ngFor="let item of items">
    <div class="product-btn-container">
      <button
        class="button"
        (click)="pickOptions(item)"
        (disabled)="(item.stock_quantity === 0)"
      >
        {{ item.title
        }}{{ (item.stock_quantity === 0 && ' - OUT OF STOCK') || '' }}
      </button>
    </div>
  </ng-container>
</section>
