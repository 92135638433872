<div class="{{ item.colour_scheme }} item" *ngFor="let item of items" class="">
  <div class="item-contents" (click)="navigate(item)">
    <div class="image">
      <img
        alt="{{ item.menu_title }}"
        loading="lazy"
        [src]="item.thumbnailUrl ? item.thumbnailUrl : item.imageUrl"
      />
    </div>

    <div class="description">
      <h3>{{ item.menu_title }}</h3>
    </div>
    <div class="item-price">
      <product-list-price [product]="item"></product-list-price>
    </div>
  </div>
</div>
