<section class="pins {{scheme}}">
  <div
    *ngFor="let product of this.sortedProduct"
    (click)="display(product)"
    class="pin-container"
  >
    <div *ngIf="product.type === 'payment'" class="pin">
      <span class="fa fa-info-circle"></span> {{ product.title }}
    </div>
  </div>
</section>
<section class="accordions {{scheme}}">
  <section
    *ngFor="let info of this.sortedProduct"
    class="accordion extra-info-accordion"
  >
    <div *ngIf="info.type === 'product'">
      <header
        (click)="setAccordion(info.title)"
        class="dropdown button secondary tiny"
      >
        <h1>{{ info.title }}</h1>
      </header>

      <section *ngIf="displayAccordion == info.title" class="expanded">
        <div class="body">
          <div>
            <div [innerHTML]="info.description"></div>
            <div
              *ngIf="info.file_type?.indexOf('image') > -1 && !info.thumbnails"
            >
              <img
                alt="{{ info.file_title }}"
                src="{{
                  '/product-extra-info-file/' + info.id + '/' + info.file_slug
                }}"
              />
            </div>
            <div
              *ngIf="info.file_type?.indexOf('image') == -1 && info.file_title"
              class="download"
            >
              <a
                href="/product-extra-info-file/{{ info.id }}/{{
                  info.file_slug
                }}"
                target="_self"
              >
              <img src="/app/assets/img/download.png" />

                {{ removeFileExtension(info.file_title) }}
              </a>
            </div>

            <image-carousel
              *ngIf="info.thumbnails"
              [slug]="info.slug"
              class="modal-carousel"
              [items]="info.thumbnails"
            ></image-carousel>
          </div>
        </div>
      </section>
    </div>
  </section>
</section>
