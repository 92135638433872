
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NoPathFound} from './angular/component/not-found/app.comonent';
import {HomepageComponent} from './angular/modules/homepage/components/app.component';
import { TestComponent } from './angular/modules/test/components/app.component';

const routes: Routes = [
  {path: '', component: HomepageComponent},
  {
    path: '**',
    component: TestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
