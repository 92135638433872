import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import environment from '../../environment/index.js';
import {AuthService} from './auth.service';
// import { PageEvent } from '@angular/material/paginator';
// import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Injectable()
export class ApiService {
  public projectFormFields = new BehaviorSubject<any>(null);
  api = environment.settings.awsUrl;
  private messageResponseTime = 10000;
  private messageMsPerChar = 500;

  constructor(
    private readonly Http: HttpClient,
    private readonly auth: AuthService // private SnackBar: MatSnackBar
  ) {}

  post(endpoint, body, endpointUrl?: string) {
    endpointUrl = endpointUrl || this.api;
    return this.request(`${endpointUrl}/${endpoint}`, 'POST', body);
  }
  put(endpoint, body, endpointUrl?: string) {
    endpointUrl = endpointUrl || this.api;
    return this.request(`${endpointUrl}/${endpoint}`, 'PUT', body);
  }

  patch(endpoint, body, endpointUrl?: string) {
    endpointUrl = endpointUrl || this.api;
    return this.request(`${endpointUrl}/${endpoint}`, 'PATCH', body);
  }

  get(endpoint: string) {
    return this.request(endpoint);
  }

  public getWithoutPageEventFilter(
    endpoint: string,
    filter?: {},
    endpointUrl?: string
  ) {
    const method = 'GET';
    endpointUrl = endpointUrl || this.api;
    return this.request(`${endpointUrl}/${endpoint}`, method, {
      ...filter,
    });
  }

  //   public getWithPageEventFilter(
  //     endpoint: string,
  //     PageEvent: PageEvent,
  //     filter?: {},
  //     endpointUrl?: string
  //   ) {
  //     const method = 'GET';
  //     const { pageIndex = 0, pageSize = 24 } = PageEvent || {};
  //     endpointUrl = endpointUrl || this.api;
  //     return this.request(`${endpointUrl}/${endpoint}`, method, {
  //       pageIndex,
  //       pageSize,
  //       ...filter,
  //     });
  //   }

  //   public getWithPageEvent(
  //     endpoint: string,
  //     PageEvent: PageEvent,
  //     endpointUrl?: string
  //   ) {
  //     const method = 'GET';
  //     endpointUrl = endpointUrl || this.api;

  //     if (!!PageEvent) {
  //       if (endpoint.includes('page-index')) {
  //         endpoint = endpoint.replace('/page-size/24/page-index/0', '');
  //       }

  //       endpoint += `/page-size/${PageEvent.pageSize}/page-index/${PageEvent.pageIndex}`;
  //     }
  //     return this.request(`${endpointUrl}/${endpoint}`, method);
  //   }

  //   delete(endpoint, endpointUrl?: string, body?) {
  //     endpointUrl = endpointUrl || this.api;
  //     return this.request(`${endpointUrl}/${endpoint}`, 'DELETE', body);
  //   }

  private async request(
    endpoint: string,
    method = 'GET',
    params?: {}
  ): Promise<any> {
    const requestLocation = window.location.href;
    const options = this.httpOptions(method, {...params});

    const url = endpoint;

    return this.Http.request(method, url, options)
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(e => {
        switch (e.status) {
          case 401:
            if ( !requestLocation.includes('Login')) {
              localStorage.setItem('loginRedirect', requestLocation);
            }
            this.auth.logout();
            break;
        }
      });
  }

  private httpOptions(
    method: string,
    params?: {[key: string]: string},
    isLeisure = false
  ) {
    const optionKey = method === 'GET' ? 'params' : 'body';
    const token = isLeisure
      ? this.auth.getLeisureToken()
      : this.auth.getToken();

    const options = Object.keys(params).reduce((response, key: string) => {
      if (params[key] !== null) {
        response[key] = params[key];
      }

      return response;
    }, {});

    return {
      headers: {Authorization: `${token}`},
      [optionKey]: options,
    };
  }

  //   public handleError(message: any = 'An error occurred.'): Promise<void> {
  //     const config: MatSnackBarConfig = new MatSnackBarConfig();

  //     config.duration = this.getMessageTime(message);
  //     config.panelClass = ['error'];
  //     if (message?.error) {
  //       message = message.error?.error;
  //     } else if (message?.message) {
  //       message = message.message;
  //     }
  //     return this.SnackBar.open(message, 'Close', config)
  //       .afterOpened()
  //       .toPromise();
  //   }

  //   public handleSuccess(message: string) {
  //     const config: MatSnackBarConfig = new MatSnackBarConfig();

  //     config.duration = this.getMessageTime(message);
  //     config.panelClass = ['success'];

  //     return this.SnackBar.open(message, 'Close', config)
  //       .afterOpened()
  //       .toPromise();
  //   }

  private getMessageTime(message: string) {
    return this.messageResponseTime + message.length * this.messageMsPerChar;
  }
}
